<template>
  <AffiliateLayout>
    <section>
      <b-row class="row2">
        <b-col md="3">
          <b-card
            v-if="memberInfo"
            class="card-sticky"
          >
            <div class="mb-2">
              <h4 class="text-primary">
                Cộng tác viên
              </h4>
            </div>
            <div class="mb-2 text-center">
              <b-avatar
                size="70px"
                :src="memberInfo.customer[0].avatar || '/logo.png'"
              />
            </div>
            <div class="mb-2 text-center">
              <h3 class="text-primary mb-1">
                {{ memberInfo.customer[0].fullname }}
              </h3>

              <b-badge variant="light-success mb-1">
                Xếp hạng: #100
              </b-badge>

              <div class="d-flex justify-content-between">
                <h3 class="text-warning">
                  {{ memberInfo.customer[0].point }} IFS
                </h3>
                <router-link to="points-history">
                  <small>Lịch sử điểm</small>
                  <feather-icon
                    icon="ArrowRightIcon"
                    class="ml-25"
                  />
                </router-link>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex flex-column mb-1">
                <strong>Sinh nhật</strong>
                <span>{{ memberInfo.customer[0].birthday || 'Không xác định' }}</span>
              </div>
              <div class="d-flex flex-column mb-1">
                <strong>CMND / CCCD</strong>
                <span>{{ memberInfo.cmnd }}</span>
              </div>

              <div class="d-flex flex-column">
                <strong>Địa chỉ</strong>
                <span>{{ memberInfo.customer[0].address || 'Không xác định' }}</span>
              </div>

              <div
                v-for="(item, index) in memberInfo.bank"
                :key="index"
              >
                <div class="d-flex flex-column mt-1">
                  <strong>Ngân hàng</strong>
                  <span>{{ item.name || 'Không xác định' }}</span>
                  <strong>STK</strong>
                  <span>{{ item.account_number || 'Không xác định' }}</span>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="9">
          <!-- ORDER HISTORY -->
          <b-card>
            <h4 class="text-primary mb-2">
              Lịch sử đơn hàng
            </h4>

            <div class="d-flex">
              <b-form-group
                style="min-width: 220px;"
                class="mr-md-1"
              >
                <flat-pickr
                  v-model="orderDatePicker.fromToDate"
                  class="form-control d-inline-block"
                  :config="configDateRangePicker"
                  placeholder="Ngày bắt đầu và kết thúc"
                />
              </b-form-group>

              <b-form-group
                style="min-width: 220px;"
                class="mr-md-1"
              >
                <v-select
                  v-model="selectedStatus"
                  :options="filterList"
                  label="name"
                  placeholder="Bộ lọc"
                />
              </b-form-group>

              <b-input-group class="mb-2">
                <b-form-input
                  v-model="orderHistorySearchTerm"
                  placeholder="Nhập mã đơn..."
                />
                <b-input-group-append>
                  <b-button variant="outline-primary">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="history-grid">
              <div class="history-grid__item">
                <div>
                  <feather-icon
                    icon="ShoppingBagIcon"
                    size="32"
                    class="text-primary"
                  />
                </div>
                <small>Tổng đơn hàng</small>
                <h4 class="text-warning mb-0">
                  {{ orderHistoryBox ? orderHistoryBox.total_order : 0 }}
                </h4>
              </div>
              <div class="history-grid__item">
                <div>
                  <feather-icon
                    icon="DollarSignIcon"
                    size="32"
                    class="text-primary"
                  />
                </div>
                <small>Tổng doanh thu</small>
                <h4 class="text-warning mb-0">
                  {{ unitFormatOriginal(orderHistoryBox ? orderHistoryBox.revenue : 0) }}
                </h4>
              </div>
              <div class="history-grid__item">
                <div>
                  <feather-icon
                    icon="DollarSignIcon"
                    size="32"
                    class="text-primary"
                  />
                </div>
                <small>Tổng lợi nhuận</small>
                <h4 class="text-warning mb-0">
                  {{ unitFormatOriginal(orderHistoryBox ? orderHistoryBox.profit : 0) }}
                </h4>
              </div>
              <div class="history-grid__item">
                <div>
                  <feather-icon
                    icon="XCircleIcon"
                    size="32"
                    class="text-primary"
                  />
                </div>
                <small>Đơn hàng hủy</small>
                <h4 class="text-warning mb-0">
                  {{ orderHistoryBox ? orderHistoryBox.order_cancel : 0 }}
                </h4>
              </div>
            </div>

            <vue-good-table
              class="custom-good-table mt-2"
              style-class="vgt-table"
              :columns="orderHistoryFields"
              :rows="orderHistoryItems"
              :search-options="{
                enabled: true,
                externalQuery: orderHistorySearchTerm
              }"
              :pagination-options="{
                enabled: true,
                perPage:orderHistoryPageLength
              }"
            >
              <!-- Table: Head -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>

              <!-- Table: Rows -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Rank -->
                <span
                  v-if="props.column.field === 'order_no'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <b-link
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :to="`/order/edit/${props.row.id_encode}`"
                  >
                    #{{ props.row.order_no }}
                  </b-link>
                </span>

                <span
                  v-else-if="props.column.field === 'detail'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.detail"
                  >
                    {{ props.row.detail }}
                    <strong>{{ props.row.order_items_qty > 1 ? `- và ${props.row.order_items_qty - 1} sản phẩm khác` : '' }}</strong>
                  </span>
                </span>

                <span
                  v-else-if="props.column.field === 'created_at'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.created_at"
                  >
                    {{ dateFormatTime(props.row.created_at) }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'total'"
                  class="d-flex flex-wrap align-items-center justify-content-end"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.total"
                  >
                    {{ unitFormatOriginal(Number(props.row.total)) }}
                  </span>
                </span>

                <!-- Column: Profit -->
                <span
                  v-else-if="props.column.field === 'profit'"
                  class="d-flex flex-wrap align-items-center justify-content-end"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.profit"
                  >
                    {{ unitFormatOriginal(Number(props.row.profit)) }}
                  </span>
                </span>

                <!-- Column: Actions -->
                <span
                  v-else-if="props.column.field === 'status_text'"
                  class="justify-content-end text-end"
                >
                  <b-badge
                    variant="light-success"
                  >{{ props.row.status_text }}</b-badge>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap p-2">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="orderHistoryPageLength"
                      :options="['10','30','100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="orderHistoryPageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>

          <!-- REFERRALS HISTORY -->
          <b-card>
            <h4 class="text-primary mb-2">
              Lịch sử Referrals
            </h4>

            <div
              class="history-grid mb-2"
              style="--grid-template: repeat(auto-fit, minmax(280px, 1fr))"
            >
              <div class="history-grid__item">
                <div>
                  <feather-icon
                    icon="ShoppingBagIcon"
                    size="32"
                    class="text-primary"
                  />
                </div>
                <small>Tổng giới thiệu</small>
                <h4 class="text-warning mb-0">
                  100
                </h4>
              </div>
              <div class="history-grid__item">
                <div>
                  <feather-icon
                    icon="DollarSignIcon"
                    size="32"
                    class="text-primary"
                  />
                </div>
                <small>Tổng hoa hồng</small>
                <h4 class="text-warning mb-0">
                  30.000.000₫
                </h4>
              </div>
            </div>

            <div class="filter-tab w-100 mb-2">
              <div
                v-for="item in filterTabs"
                :key="item.id"
                :class="['filter-tab__item', {'active': filterSelected == item.id}]"
                @click="filterSelected = item.id"
              >
                {{ $t(item.label) }}
              </div>
            </div>

            <vue-good-table
              class="custom-good-table mt-2"
              style-class="vgt-table"
              :columns="referralFields"
              :rows="referralItems"
              :pagination-options="{
                enabled: true,
                perPage:referralPageLength
              }"
            >
              <!-- Table: Head -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>

              <!-- Table: Rows -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Rank -->
                <span
                  v-if="props.column.field === 'id'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.id"
                  >
                    {{ props.row.id }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'reward'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.reward"
                  >
                    {{ props.row.reward }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'detail'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.detail"
                  >
                    {{ props.row.detail }}
                  </span>
                </span>

                <!-- Column: Actions -->
                <span
                  v-else-if="props.column.field === 'status'"
                  class="d-inline-flex text-center"
                >
                  <div
                    v-if="props.row.status === 0"
                    class="d-flex align-items-center"
                  >
                    <b-button
                      variant="primary"
                      class="mr-1"
                    >
                      Phê duyệt
                    </b-button>
                    <b-button
                      v-b-modal.modal-referral-reject
                      variant="outline-primary"
                    >
                      Từ chối
                    </b-button>
                  </div>
                  <div
                    v-else
                  >
                    <b-badge
                      variant="light-success"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      Đã duyệt
                    </b-badge>
                  </div>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap p-2">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="referralPageLength"
                      :options="['10','30','100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="referralPageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>

          <!-- CUSTOMER LIST -->
          <b-card>
            <h4 class="text-primary mb-2">
              Danh sách khách hàng
            </h4>

            <b-input-group class="mb-2">
              <b-form-input
                v-model="customerSearchTerm"
                placeholder="Nhập tên khách hàng..."
              />
              <b-input-group-append>
                <b-button variant="outline-primary">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <vue-good-table
              class="custom-good-table mt-2"
              style-class="vgt-table"
              :columns="customerFields"
              :rows="customerItems"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                externalQuery: customerSearchTerm
              }"
              :pagination-options="{
                enabled: true,
                perPage: customerPageLength
              }"
            >
              <!-- Table: Head -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>

              <!-- Table: Rows -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Rank -->
                <span
                  v-if="props.column.field === 'fullname'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.fullname"
                    @click="onShowBuyerModal(props.row.originalIndex)"
                  >
                    {{ props.row.fullname }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'phone_number'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.phone_number"
                  >
                    {{ props.row.phone_number }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'email'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.email"
                  >
                    {{ props.row.email }}
                  </span>
                </span>

                <!-- Column: Actions -->
                <span
                  v-else-if="props.column.field === 'spend'"
                  class="d-inline-flex text-center"
                >
                  {{ props.row.spend }}
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap p-2">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="customerPageLength"
                      :options="['10','30','100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage: value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="customerPageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage: value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-col>
      </b-row>

      <!-- REFERRAL REJECT MODAL -->
      <b-modal
        id="modal-referral-reject"
        size="sm"
        centered
        title="Từ chối Referral"
        header-bg-variant="danger"
        header-text-variant="light"
        header-close-variant="danger"
        title-tag="h4"
        ok-title="Gởi"
        ok-variant="danger"
        cancel-title="Thoát"
        cancel-variant="light"
      >
        <b-form-group
          label="Nhập lý do"
        >
          <b-form-textarea
            placeholder="Nhập lý do"
            rows="5"
          />
        </b-form-group>
      </b-modal>

      <!-- BUYER INFO MODAL -->
      <b-modal
        v-model="buyerInfoShow"
        centered
        title="Thông tin khách hàng"
        header-bg-variant="primary"
        header-text-variant="light"
        header-close-variant="danger"
        title-tag="h4"
        ok-title="Đóng"
        ok-variant="primary"
        :ok-only="true"
      >
        <div
          v-if="buyerInfo"
        >
          <b-form-group>
            <div class="img-avata">
              <img
                :src="buyerInfo.avatar || '/logo.png'"
                :alt="buyerInfo.fullname"
              >
            </div>
          </b-form-group>
          <b-row
            class="row2 mb-2"
            style="row-gap: 0.5rem"
          >
            <b-col sm="12">
              <h4>Thông tin cơ bản</h4>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Tên đầy đủ"
              >
                <h5>{{ buyerInfo.fullname }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Giới tính"
              >
                <h5>{{ buyerInfo.gender == 0 ? 'Nữ' : 'Nam' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Ngày sinh"
              >
                <h5>{{ buyerInfo.birthday || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Số điện thoại"
              >
                <h5>{{ buyerInfo.phone || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Email"
              >
                <h5>{{ buyerInfo.email || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                class="mb-0"
                label="Địa chỉ"
              >
                <h5>{{ buyerInfo.address || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            class="row2"
            style="row-gap: 0.5rem"
          >
            <b-col sm="12">
              <h4>Thông tin thêm</h4>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Mạng xã hội"
              >
                <h5>{{ buyerInfo.social || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Nguồn"
                class="mb-0"
              >
                <h5>{{ sourceRender(buyerInfo.source) || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Ghi chú"
                class="mb-0"
              >
                <h5>{{ buyerInfo.note || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </section>
  </AffiliateLayout>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BButton,
  BPagination,
  BLink,
  BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import MixinAffiliate from '../mixin'
import AffiliateLayout from '../layouts/AffiliateLayout.vue'

export default {
  name: 'MemberDetail',
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BButton,
    BPagination,
    BLink,
    BModal,
    VueGoodTable,
    flatPickr,
    AffiliateLayout,
    vSelect,
  },
  mixins: [MixinAffiliate],
  props: {},

  data() {
    return {
      memberInfo: null,
      filterList: [
        {
          id: 1,
          name: 'Mới',
        },
        {
          id: 2,
          name: 'Xác nhận',
        },
        {
          id: 3,
          name: '...',
        },
      ],
      selectedStatus: null,
      customerList: [],
      orderHistoryFields: [
        {
          field: 'order_no', label: 'Mã đơn hàng', sortable: false, thClass: 'text-left',
        },
        {
          field: 'detail', label: 'Chi tiết đơn', sortable: false, thClass: 'text-left',
        },
        {
          field: 'created_at', label: 'Thời gian đặt', sortable: false, thClass: 'text-left',
        },
        {
          field: 'total', label: 'Giá bán', sortable: false, thClass: 'text-end', tdClass: 'text-end',
        },
        {
          field: 'profit', label: 'Lợi nhuận', sortable: false, thClass: 'text-end', tdClass: 'text-end',
        },
        {
          field: 'status_text', label: 'Trạng thái', sortable: false, tdClass: 'text-end', thClass: 'text-end',
        },
      ],
      orderHistoryItems: [],
      orderHistoryPageLength: 10,
      orderHistorySearchTerm: '',
      orderDatePicker: {
        startDate: null,
        endDate: null,
        fromToDate: null,
      },
      orderHistoryBox: null,
      configDateRangePicker: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        defaultDate: [(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)).toISOString(), (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)).toISOString()],
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.orderDatePicker.startDate = start.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.orderDatePicker.endDate = end.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.loadOrderList()
          }
        },
      },
      filterSelected: 'all',
      filterTabs: [
        {
          id: 'all',
          label: 'All',
        },
        {
          id: 'phan-thuong-gioi-thieu',
          label: 'Phần thưởng giới thiệu',
        },
        {
          id: 'chi-tiet',
          label: 'Chi tiết',
        },
        {
          id: 'trang-thai',
          label: 'Trạng thái',
        },
      ],
      referralFields: [
        {
          field: 'id', label: 'ID', sortable: false, thClass: 'text-left',
        },
        {
          field: 'reward', label: 'Phần thưởng', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'detail', label: 'Chi tiết', sortable: false, tdClass: 'text-left',
        },
        {
          field: 'status', label: 'Trạng thái', sortable: false, tdClass: 'text-center',
        },
      ],
      referralItems: [
        {
          id: '#01234',
          reward: '500.000₫',
          detail: 'Giới thiệu bạn mới',
          status: 0,
        },
        {
          id: '#01234',
          reward: '500.000₫',
          detail: 'Giới thiệu bạn mới',
          status: 1,
        },
      ],
      referralPageLength: 10,
      customerFields: [
        {
          field: 'fullname', label: 'Tên đầy đủ', sortable: false, thClass: 'text-left',
        },
        {
          field: 'phone', label: 'SĐT', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'email', label: 'Email', sortable: false, tdClass: 'text-left',
        },
        {
          field: 'spend', label: 'Chi tiêu', sortable: false, tdClass: 'text-center',
        },
      ],
      customerItems: [],
      customerPageLength: 10,
      customerSearchTerm: '',
      teamPageLength: 10,
      teamSearchTerm: '',
      buyerInfoShow: false,
      buyerInfo: null,
    }
  },
  created() {
    this.loadInfoMember()
    this.setDefaultDateRange()
  },
  methods: {
    statusClass(status) {
      let result = ''
      switch (status) {
        case 1:
          result = 'text-warning'
          break
        case 2:
          result = 'text-success'
          break
        case 3:
          result = 'text-danger'
          break
        default:
          result = 'text-warning'
          break
      }
      return result
    },
    setDefaultDateRange() {
      const d = new Date()
      const time = d.getTime()
      const d2 = new Date(time - 31556926000)
      this.configDateRangePicker.defaultDate = [d2.toISOString(), d.toISOString()]
      this.orderDatePicker.startDate = `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`
      this.orderDatePicker.endDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    },
    async loadInfoMember() {
      const memberID = this.$route.params.id
      const res = await MixinAffiliate.get(`/api/v1/customer/${memberID}`)

      if (!res.status) this.$router.push('/affiliate/member/list')
      this.memberInfo = res.data

      this.loadCustomerList()
      this.loadOrderList()
    },
    async loadCustomerList() {
      if (!this.memberInfo || !this.memberInfo.id) return
      const params = {
        customer_id: this.memberInfo.id,
      }
      const res = await MixinAffiliate.get('/api/v1/auth/buyers', params)
      if (!res.status) return

      this.customerItems = res.data.items
    },
    async loadOrderList() {
      if (!this.memberInfo || !this.memberInfo.customer_id) return
      const params = {
        date_from: this.orderDatePicker.startDate,
        date_to: this.orderDatePicker.endDate,
        customer_id: this.memberInfo.customer_id,
      }
      const res = await MixinAffiliate.get('/api/v1/auth/orders', params)
      const res2 = await MixinAffiliate.get('/api/v1/auth/dashboard_box_operating_margin', params)
      if (res.status) {
        this.orderHistoryItems = []
        const temp = []
        res.data.items.map(x => {
          temp.push({
            ...x,
            detail: x.order_item[0].product_nm,
            order_items_qty: x.order_item.length,
          })
          return true
        })
        this.orderHistoryItems = temp
      }
      if (res2.status) {
        this.orderHistoryBox = res2.data
      }
    },
    onShowBuyerModal(index) {
      this.buyerInfoShow = true
      this.buyerInfo = this.customerItems[index]
    },
    sourceRender(status) {
      let result = ''
      switch (status) {
        case 1:
          result = 'facebook'
          break

        case 2:
          result = 'tiktok'
          break

        case 3:
          result = 'website'
          break

        case 4:
          result = 'affiliate'
          break

        default:
          result = 'website'
          break
      }
      return result
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import url(./member.scss);
.img-avata{
  background-color: #f0f0f0;
  padding: 16px;
  text-align: center;
  img{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.custom-good-table {
  .text-end {
    text-align: end !important;
  }
}
</style>
